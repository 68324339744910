/**
 * Example Args
 * {
 "ruleId": 1,
 "guideId": 2,
 "enabled": true,
 "booleanValue": false,
 "numericValue": 30.0, 
 "choiceValue": "plus",
 "exceptions": ["new-pattern1", "new-pattern2"]
}

 */

import { axios } from "@/lib/axios";
import { MutationConfig, queryClient } from "@/lib/react-query";
import { useMutation } from "react-query";
import { StyleGuideRule } from "../types";

export const saveStyleGuideRule = async (
  rule: Omit<StyleGuideRule, "id" | "updatedAt" | "type">
): Promise<StyleGuideRule> => {
  return axios.post("/saveStyleGuideRule", rule);
};

type UseSaveStyleGuideRuleOptions = {
  config?: MutationConfig<typeof saveStyleGuideRule>;
};

export const useSaveStyleGuideRule = ({
  config,
}: UseSaveStyleGuideRuleOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: saveStyleGuideRule,
    onSuccess: (data, variables, context) => {
      // Invalidate the specific style guide query
      queryClient.invalidateQueries(["styleGuide", data.guideId]);
      // Invalidate the list of style guides for the organization
      queryClient.invalidateQueries(["styleGuidesForOrg"]);

      // If there's any onSuccess callback in the config, call it
      if (config?.onSuccess) {
        config.onSuccess(data, variables, context);
      }
    },
  });
};
