import { Table } from "@tanstack/react-table";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
} from "lucide-react";
import { Button } from "../Button";

type PaginationProps = {
  documentsCount: number;
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  onPageChange: (newPage: number) => void;
  onItemsPerPageChange: (newItemsPerPage: number) => void;
  table: Table<any>;
  type: "document" | "template" | "knowledge document";
};

export const Pagination: React.FC<PaginationProps> = ({
  documentsCount,
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
  onItemsPerPageChange,
  table,
  type,
}) => {
  const totalPages = Math.ceil(documentsCount / itemsPerPage);

  if (table.getFilteredSelectedRowModel().rows.length > 0) {
    return (
      <div className="flex items-center justify-between px-4 py-3 h-full dark:bg-zinc-900">
        <div className="hidden sm:block ml-3">
          <p className="text-xs text-zinc-500">
            <span className="font-medium text-xs">
              {table.getFilteredSelectedRowModel().rows.length}
            </span>{" "}
            of{" "}
            <span className="font-medium text-xs">
              {table.getFilteredRowModel().rows.length}
            </span>{" "}
            of {type}(s) selected
          </p>
        </div>
      </div>
    );
  }

  return (
    <nav
      className="flex items-center justify-between px-4 py-3 h-full w-full dark:bg-zinc-900"
      aria-label="Pagination"
    >
      <div className="hidden sm:block ml-3">
        <p className="text-xs text-zinc-500 dark:text-white">
          <span className="font-medium text-xs">
            {(currentPage - 1) * itemsPerPage + 1}
          </span>{" "}
          -{" "}
          <span className="font-medium text-xs">
            {Math.min(currentPage * itemsPerPage, documentsCount)}
          </span>{" "}
          of <span className="font-medium text-xs">{documentsCount}</span>{" "}
          {type}s
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end items-center">
        {/*<Combobox
          label="Items per page"
          showEndIcon={true}
          value={itemsPerPage}
          buttonVariant="outlineBlur"
          className="mr-2 text-zinc-500 dark:text-white h-7"
          options={[
            {
              label: "20 per page",
              value: 20,
              onClick: () => onItemsPerPageChange(20),
            },
            {
              label: "50 per page",
              value: 50,
              onClick: () => onItemsPerPageChange(50),
            },
            {
              label: "100 per page",
              value: 100,
              onClick: () => onItemsPerPageChange(100),
            },
          ]}
        />*/}
        <Button
          variant="buttonIcon"
          className="z-[1]"
          buttonIcon={<ChevronsLeftIcon />}
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
        />
        <Button
          variant="buttonIcon"
          className="z-[1]"
          buttonIcon={<ChevronLeftIcon />}
          onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
          disabled={currentPage === 1}
        />
        <Button
          variant="buttonIcon"
          className="z-[1]"
          buttonIcon={<ChevronRightIcon />}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
        <Button
          variant="buttonIcon"
          className="z-[1]"
          buttonIcon={<ChevronsRightIcon />}
          onClick={() => onPageChange(totalPages - 1)}
          disabled={currentPage === totalPages}
        />
      </div>
    </nav>
  );
};
