import {
  Button,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "@/components/Elements";
import { useNotificationStore } from "@/stores/notifications";
import { cn } from "@/utils/style";
import { ChevronRightIcon } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useSaveStyleGuideRule } from "../../api/saveStyleGuideRule";
import { useUpdateStyleGuide } from "../../api/updateStyleGuide";
import {
  BooleanStyleGuideRule,
  ChoiceStyleGuideRule,
  NumericStyleGuideRule,
  PlanAccessLevel,
  SpecificRuleDefinition,
  StyleGuide,
  StyleGuideRule,
} from "../../types";
import { StyleGuideRuleField } from "../StyleGuideRuleField";

interface StyleGuideFormProps {
  initialData: StyleGuide;
  ruleDefinitions: SpecificRuleDefinition[];
  onSuccess?: (styleGuide: StyleGuide) => void;
  userPlanLevel: PlanAccessLevel;
}

export interface GroupedRuleDefinitions {
  [category: string]: SpecificRuleDefinition[];
}

const planOrder = ["free", "basic", "team", "enterprise"];

export function groupRuleDefinitionsByCategory(
  ruleDefinitions: SpecificRuleDefinition[]
): GroupedRuleDefinitions {
  const grouped = ruleDefinitions.reduce((acc, rule) => {
    const capitalizedCategory = rule.category
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    if (!acc[capitalizedCategory]) {
      acc[capitalizedCategory] = [];
    }
    acc[capitalizedCategory].push(rule);
    return acc;
  }, {} as GroupedRuleDefinitions);

  // Sort rules within each category
  Object.keys(grouped).forEach((category) => {
    grouped[category].sort(
      (a, b) =>
        planOrder.indexOf(a.minimumPlanLevel) -
        planOrder.indexOf(b.minimumPlanLevel)
    );
  });

  const sortedEntries = Object.entries(grouped).sort(
    ([_categoryA, rulesA], [_categoryB, rulesB]) => {
      const minPlanA = planOrder.indexOf(rulesA[0].minimumPlanLevel);
      const minPlanB = planOrder.indexOf(rulesB[0].minimumPlanLevel);
      return minPlanA - minPlanB;
    }
  );

  return Object.fromEntries(sortedEntries);
}

export const StyleGuideForm: React.FC<StyleGuideFormProps> = ({
  initialData,
  ruleDefinitions,
  onSuccess = () => {},
  userPlanLevel,
}) => {
  const queryClient = useQueryClient();
  const updateStyleGuideMutation = useUpdateStyleGuide();
  const saveStyleGuideRuleMutation = useSaveStyleGuideRule();
  const { addNotification } = useNotificationStore();
  const groupedRuleDefinitions = useMemo(
    () => groupRuleDefinitionsByCategory(ruleDefinitions),
    [ruleDefinitions]
  );

  const form = useForm<
    Omit<StyleGuide, "id" | "createdAt" | "updatedAt" | "orgId" | "rules">
  >({
    defaultValues: {
      name: initialData?.name || "",
      description: initialData?.description || "",
    },
  });

  const [rules, setRules] = useState<StyleGuideRule[]>(initialData.rules || []);
  const [openCategories, setOpenCategories] = useState<Record<string, boolean>>(
    {}
  );

  useEffect(() => {
    form.reset({
      name: initialData?.name || "",
      description: initialData?.description || "",
    });
    setRules(initialData.rules || []);
  }, [initialData, form]);

  const handleSubmit = async (
    data: Omit<StyleGuide, "id" | "createdAt" | "updatedAt" | "orgId" | "rules">
  ) => {
    try {
      await updateStyleGuideMutation.mutateAsync({
        id: initialData.id,
        updates: data,
      });
      await queryClient.invalidateQueries(["styleGuidesForOrg"]);
      addNotification({
        title: "Style Guide Updated",
        message: "Your style guide has been updated successfully.",
        type: "success",
      });
      onSuccess({
        ...initialData,
        ...data,
        rules: rules,
      });
    } catch (error) {
      addNotification({
        title: "Error",
        message: "Failed to update style guide. Please try again.",
        type: "error",
      });
    }
  };

  const handleRuleChange = async (updatedRule: StyleGuideRule) => {
    try {
      const payload = {
        guideId: updatedRule.guideId,
        ruleId: updatedRule.ruleId,
        enabled: updatedRule.enabled,
        // @ts-ignore
        booleanValue: updatedRule.booleanValue,
        // @ts-ignore
        choiceValue: updatedRule.choiceValue,
        // @ts-ignore
        numericValue: updatedRule.numericValue,
      };

      const savedRule = await saveStyleGuideRuleMutation.mutateAsync(payload);

      setRules((prevRules) => {
        const newRules = prevRules.map((rule) =>
          rule.ruleId === savedRule.ruleId ? savedRule : rule
        );
        if (!prevRules.some((rule) => rule.ruleId === savedRule.ruleId)) {
          newRules.push(savedRule);
        }
        return newRules;
      });

      addNotification({
        title: "Rule Updated",
        message: "The rule has been updated successfully.",
        type: "success",
      });
    } catch (error) {
      addNotification({
        title: "Error",
        message: "Failed to update rule. Please try again.",
        type: "error",
      });
    }
  };

  const createDefaultRule = (
    ruleDefinition: SpecificRuleDefinition
  ): Omit<StyleGuideRule, "id" | "updatedAt"> => {
    const baseRule = {
      guideId: initialData.id,
      ruleId: ruleDefinition.id,
      enabled: false, // Set to false by default for new rules
      type: ruleDefinition.ruleType,
    };

    switch (ruleDefinition.ruleType) {
      case "boolean":
        return {
          ...baseRule,
          booleanValue: ruleDefinition.defaultBooleanValue || false,
        } as Omit<BooleanStyleGuideRule, "id" | "updatedAt">;
      case "choice":
        return {
          ...baseRule,
          choiceValue: ruleDefinition.defaultChoiceValue || "",
        } as Omit<ChoiceStyleGuideRule, "id" | "updatedAt">;
      case "numeric":
        return {
          ...baseRule,
          numericValue: ruleDefinition.defaultNumericValue || 0,
        } as Omit<NumericStyleGuideRule, "id" | "updatedAt">;
    }
  };

  const toggleCategory = (category: string) => {
    setOpenCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="name"
          rules={{ required: "Name is required" }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Style Guide Name</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="Enter a name for your style guide"
                  onChange={(e) => {
                    field.onChange(e);
                    form.trigger("name");
                  }}
                  maxLength={40}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="Describe your style guide"
                  onChange={(e) => {
                    field.onChange(e);
                    form.trigger("description");
                  }}
                  maxLength={120}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-end">
          <Button
            type="submit"
            isLoading={updateStyleGuideMutation.isLoading}
            disabled={!form.formState.isDirty || !form.formState.isValid}
          >
            Update Name & Description
          </Button>
        </div>

        <div className="space-y-2">
          {Object.entries(groupedRuleDefinitions).map(
            ([category, ruleDefinitions]) => (
              <Collapsible
                key={category}
                className="border rounded-md overflow-hidden"
                open={openCategories[category]}
                onOpenChange={() => toggleCategory(category)}
              >
                <CollapsibleTrigger className="w-full text-left bg-zinc-100 dark:bg-zinc-800 hover:bg-zinc-200 dark:hover:bg-zinc-700 transition-colors">
                  <div className="flex items-center justify-between p-4">
                    <span className="font-semibold text-zinc-900 dark:text-white">
                      {category}
                    </span>
                    <ChevronRightIcon
                      className={cn(
                        "w-4 h-4 text-zinc-400 transition-transform duration-200",
                        openCategories[category] && "transform rotate-90"
                      )}
                    />
                  </div>
                </CollapsibleTrigger>
                <CollapsibleContent className="p-4 bg-white dark:bg-zinc-900">
                  <div className="space-y-4">
                    {ruleDefinitions.map((ruleDefinition) => {
                      const existingRule = rules.find(
                        (rule) => rule.ruleId === ruleDefinition.id
                      );
                      const ruleToUse =
                        existingRule || createDefaultRule(ruleDefinition);
                      const upgradeRequired =
                        planOrder.indexOf(ruleDefinition.minimumPlanLevel) >
                        planOrder.indexOf(userPlanLevel);

                      return (
                        <StyleGuideRuleField
                          key={ruleDefinition.id}
                          ruleDefinition={ruleDefinition}
                          styleGuideRule={ruleToUse as StyleGuideRule}
                          onRuleChange={handleRuleChange}
                          upgradeRequired={upgradeRequired}
                        />
                      );
                    })}
                  </div>
                </CollapsibleContent>
              </Collapsible>
            )
          )}
        </div>
      </form>
    </Form>
  );
};
