import { Button } from "@/components/Elements";
import { cn } from "@/utils/style";
import { TrashIcon } from "@heroicons/react/24/outline";
import React from "react";
import { StyleGuide } from "../../types";

interface StyleGuideListItemProps {
  styleGuide: StyleGuide;
  onSelect: () => void;
  isSelected: boolean;
  deleteStyleGuide: () => void;
}

export const StyleGuideListItem: React.FC<StyleGuideListItemProps> = ({
  styleGuide,
  onSelect,
  isSelected,
  deleteStyleGuide,
}) => {
  return (
    <div
      className={cn(
        "p-2 cursor-pointer transition-all border-b border-gray-200 dark:border-gray-700 hover:shadow-md relative",
        isSelected
          ? "bg-emerald-100 dark:bg-emerald-900 text-emerald-800 dark:text-emerald-100"
          : "hover:bg-gray-50 dark:hover:bg-gray-800 text-gray-900 dark:text-gray-100"
      )}
      onClick={onSelect}
    >
      <Button
        variant="buttonIcon"
        className="absolute top-2 right-2"
        buttonIcon={<TrashIcon className="h-4 w-4" />}
        tooltipContent="Delete style guide"
        onClick={(e) => {
          e.stopPropagation();
          deleteStyleGuide();
        }}
      />
      <h3 className="font-semibold text-base mb-2 pr-7">{styleGuide.name}</h3>
      <p className="text-sm text-gray-600 dark:text-gray-400 mb-3 line-clamp-2">
        {styleGuide.description}
      </p>
    </div>
  );
};
