import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements";
import React from "react";

interface UnsavedChangesDialogProps {
  show: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const UnsavedChangesDialog: React.FC<UnsavedChangesDialogProps> = ({
  show,
  onCancel,
  onConfirm,
}) => {
  return (
    <Dialog open={show} onOpenChange={onCancel}>
      <DialogContent>
        <DialogHeader className="pb-0 flex items-center justify-between">
          <DialogTitle>Unsaved Changes</DialogTitle>
          <DialogCloseButton close={onCancel} />
        </DialogHeader>
        <DialogDescription className="px-4">
          You have unsaved changes. Are you sure you want to switch to another
          Brand Voice? Your changes will be lost.
        </DialogDescription>
        <DialogFooter className="px-4 pb-4">
          <Button variant="outlineBlur" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primaryBlur" onClick={onConfirm}>
            Switch
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
