import { axios } from "@/lib/axios";
import {
  ExtractFnReturnType,
  queryClient,
  QueryConfig,
} from "@/lib/react-query";
import { useQuery } from "react-query";

export const clearPrimaryHubDocuments = async (): Promise<void> => {
  return axios.post("/clearPrimaryHubDocuments");
};

type QueryFnType = typeof clearPrimaryHubDocuments;

type UseClearPrimaryHubDocumentsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useClearPrimaryHubDocuments = ({
  config,
}: UseClearPrimaryHubDocumentsOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["clearPrimaryHubDocuments"],
    queryFn: clearPrimaryHubDocuments,
    onSuccess: () => {
      queryClient.invalidateQueries(["hubDocuments"]);
    },
  });
};
