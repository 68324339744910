import { axios } from "@/lib/axios";
import {
  ExtractFnReturnType,
  queryClient,
  QueryConfig,
} from "@/lib/react-query";
import { useQuery } from "react-query";

export const clearPrimaryBrandVoices = async (): Promise<void> => {
  return axios.post("/clearPrimaryBrandVoices");
};

type QueryFnType = typeof clearPrimaryBrandVoices;

type UseClearPrimaryBrandVoicesOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useClearPrimaryBrandVoices = ({
  config,
}: UseClearPrimaryBrandVoicesOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["clearPrimaryBrandVoices"],
    queryFn: clearPrimaryBrandVoices,
    onSuccess: () => {
      queryClient.invalidateQueries(["brandVoicesForOrg"]);
    },
  });
};
