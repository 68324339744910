/**
 * Counts the number of words in a given string.
 * @param text The input string to count words from.
 * @returns The number of words in the input string.
 */
export function countWords(text: string): number {
  // Remove leading and trailing whitespace
  const trimmedText = text.trim();

  // If the trimmed text is empty, return 0
  if (trimmedText.length === 0) {
    return 0;
  }

  // Split the text by whitespace and count the resulting array elements
  const words = trimmedText.split(/\s+/);
  return words.length;
}

/**
 * Truncates the text to a specified word limit.
 * @param text The input string to truncate.
 * @param limit The maximum number of words allowed.
 * @returns The truncated text.
 */
export function truncateToWordLimit(text: string, limit: number): string {
  const words = text.split(/\s+/);
  if (words.length <= limit) {
    return text;
  }
  return words.slice(0, limit).join(" ");
}

/**
 * Checks if the text exceeds the word limit.
 * @param text The input string to check.
 * @param limit The maximum number of words allowed.
 * @returns True if the text exceeds the limit, false otherwise.
 */
export function exceedsWordLimit(text: string, limit: number): boolean {
  return countWords(text) > limit;
}
