import {
  Button,
  Form,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Elements";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/Elements/Form";
import { NotchedSlider } from "@/components/Elements/Slider/NotchedSlider";
import { useNotificationStore } from "@/stores/notifications";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useCreateBrandVoice } from "../../api/createBrandVoice";
import { ToneAnalysisResponse } from "../../api/generateToneAnalysis";
import { useGenerateTonePreview } from "../../api/generateTonePreview";
import { useGetBrandVoicesForOrg } from "../../api/getBrandVoicesForOrg";
import { useUpdateBrandVoice } from "../../api/updateBrandVoice";
import {
  assertivenessLevels,
  emotionLevels,
  formalityLevels,
  politenessLevels,
  targetAudiences,
} from "../../constants";
import { BrandVoice } from "../../types";
import { BrandVoicePreviewDialog } from "../BrandVoicePreviewDialog";
import { TextVoiceAnalysisDialog } from "../TextVoiceAnalysisDialog";

interface BrandVoiceFormProps {
  initialData?: BrandVoice;
  onSuccess?: (brandVoice: BrandVoice) => void;
  onFormChange?: (hasChanges: boolean) => void;
  overLimit: boolean;
}

export const BrandVoiceForm: React.FC<BrandVoiceFormProps> = ({
  initialData,
  onSuccess = () => {},
  onFormChange = () => {},
  overLimit,
}) => {
  const queryClient = useQueryClient();
  const createBrandVoiceMutation = useCreateBrandVoice();
  const updateBrandVoiceMutation = useUpdateBrandVoice();
  const { addNotification } = useNotificationStore();
  const [preview, setPreview] = useState<string | null>(null);
  const [showTextVoiceAnalysisDialog, setShowTextVoiceAnalysisDialog] =
    useState(false);
  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false);
  const [previewContext, setPreviewContext] = useState<string>("");
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);

  const generateTonePreviewMutation = useGenerateTonePreview();

  const { data: brandVoices } = useGetBrandVoicesForOrg();

  const isNameUnique = React.useCallback(
    (name: string) => {
      if (!brandVoices) return true;
      return !brandVoices.some(
        (voice) =>
          voice.name.toLowerCase() === name.toLowerCase() &&
          voice.id !== initialData?.id
      );
    },
    [brandVoices, initialData?.id]
  );

  const form = useForm<
    Omit<BrandVoice, "id" | "createdBy" | "createdDt" | "updatedDt">
  >({
    defaultValues: {
      name: initialData?.name || "",
      description: initialData?.description || "",
      targetAudience: initialData?.targetAudience || "",
      formalityLevel: (initialData?.formalityLevel || 3) - 1,
      politenessLevel: (initialData?.politenessLevel || 3) - 1,
      emotionLevel: (initialData?.emotionLevel || 3) - 1,
      assertivenessLevel: (initialData?.assertivenessLevel || 3) - 1,
    },
    mode: "onChange",
  });

  const handleGeneratePreview = async () => {
    try {
      setIsGeneratingPreview(true);
      const result = await generateTonePreviewMutation.mutateAsync({
        targetAudience: form.watch("targetAudience"),
        formalityLevel: form.watch("formalityLevel") + 1,
        politenessLevel: form.watch("politenessLevel") + 1,
        emotionLevel: form.watch("emotionLevel") + 1,
        assertivenessLevel: form.watch("assertivenessLevel") + 1,
        context: previewContext,
      });
      setPreview(result.content);
    } catch (error) {
      console.error("Failed to generate tone preview:", error);
      setPreview("Failed to generate preview. Please try again.");
    } finally {
      setIsGeneratingPreview(false);
    }
  };

  React.useEffect(() => {
    form.reset({
      name: initialData?.name || "",
      description: initialData?.description || "",
      targetAudience: initialData?.targetAudience || "",
      formalityLevel: (initialData?.formalityLevel || 3) - 1,
      politenessLevel: (initialData?.politenessLevel || 3) - 1,
      emotionLevel: (initialData?.emotionLevel || 3) - 1,
      assertivenessLevel: (initialData?.assertivenessLevel || 3) - 1,
    });
  }, [initialData, form]);

  const handleFormChange = useCallback(() => {
    const formValues = form.getValues();
    const hasChanges = Object.keys(formValues).some(
      (key) => formValues[key] !== initialData?.[key]
    );
    onFormChange(hasChanges);
  }, [form, initialData, onFormChange]);

  React.useEffect(() => {
    const subscription = form.watch(handleFormChange);
    return () => subscription.unsubscribe();
  }, [form, handleFormChange]);

  const handleSubmit = async (
    data: Omit<BrandVoice, "id" | "createdBy" | "createdDt" | "updatedDt">
  ) => {
    const adjustedData = {
      ...data,
      formalityLevel: (data.formalityLevel as number) + 1,
      politenessLevel: (data.politenessLevel as number) + 1,
      emotionLevel: (data.emotionLevel as number) + 1,
      assertivenessLevel: (data.assertivenessLevel as number) + 1,
    };

    try {
      if (initialData?.id) {
        await updateBrandVoiceMutation.mutateAsync({
          id: initialData.id,
          updates: adjustedData,
        });
        await Promise.all([
          queryClient.invalidateQueries(["brandVoicesForOrg"]),
          queryClient.invalidateQueries(["brandVoice", initialData.id]),
        ]);
        addNotification({
          title: "Brand Voice Updated",
          message: "Your brand voice has been updated successfully.",
          type: "success",
        });
        onSuccess(initialData);
      } else {
        const result = await createBrandVoiceMutation.mutateAsync(adjustedData);
        await queryClient.invalidateQueries(["brandVoicesForOrg"]);
        addNotification({
          title: "Brand Voice Created",
          message: "Your new brand voice has been created successfully.",
          type: "success",
        });
        onSuccess(result);
      }
    } catch (error) {
      addNotification({
        title: "Error",
        message: `Failed to ${
          initialData?.id ? "update" : "create"
        } brand voice. Please try again.`,
        type: "error",
      });
    }
  };

  const isLoading =
    createBrandVoiceMutation.isLoading || updateBrandVoiceMutation.isLoading;

  const handleApplyAnalysis = (analysis: ToneAnalysisResponse) => {
    const { tone_analysis } = analysis;
    form.setValue("formalityLevel", tone_analysis.formality.score - 1);
    form.setValue("politenessLevel", tone_analysis.politeness.score - 1);
    form.setValue("emotionLevel", tone_analysis.emotional_content.score - 1);
    form.setValue("assertivenessLevel", tone_analysis.assertiveness.score - 1);
  };

  const isDisabled = overLimit && initialData && !initialData.primary;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="name"
          rules={{
            required: "Name is required",
            maxLength: {
              value: 40,
              message: "Name must be 40 characters or less",
            },
            validate: (value) =>
              isNameUnique(value) ||
              "A brand voice with this name already exists",
          }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Brand Voice Name</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="Enter a name for your brand voice"
                  className="w-full"
                  maxLength={40}
                  disabled={isDisabled}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          rules={{
            maxLength: {
              value: 120,
              message: "Description must be 120 characters or less",
            },
          }}
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="Describe your brand voice (this will not effect the generated content)"
                  className="w-full"
                  maxLength={120}
                  disabled={isDisabled}
                />
              </FormControl>
              {fieldState.error && (
                <FormMessage>{fieldState.error.message}</FormMessage>
              )}
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="targetAudience"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Target Audience (Optional)</FormLabel>
              <Select
                onValueChange={(value) => {
                  if (value === "CLEAR") {
                    field.onChange("");
                  } else {
                    field.onChange(value);
                  }
                }}
                value={field.value ?? ""}
                disabled={isDisabled}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select target audience" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {field.value && (
                    <SelectItem value="CLEAR">Clear selection</SelectItem>
                  )}
                  {targetAudiences.map((audience) => (
                    <SelectItem key={audience} value={audience}>
                      {audience}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />

        <div className="space-y-8">
          <NotchedSlider
            name="formalityLevel"
            control={form.control}
            label="Formality Level"
            labels={formalityLevels.map((level) => ({
              label: level.short,
              tooltip: level.long,
            }))}
            headerTooltip={
              <>
                Adjust how formal or informal the tone of your content should
                be.
                <br />
                <br />
                <strong>Very Informal Example:</strong>
                <br /> "Hey! What's up? Let's dive into this—it's super cool!"
                <br />
                <br />
                <strong>Very Formal Example:</strong>
                <br /> "Dear Sir or Madam, I would like to discuss this matter
                in further detail to ensure all requirements are met."
              </>
            }
            disabled={isDisabled}
          />

          <NotchedSlider
            name="politenessLevel"
            control={form.control}
            label="Politeness Level"
            labels={politenessLevels.map((level) => ({
              label: level.short,
              tooltip: level.long,
            }))}
            headerTooltip={
              <>
                Adjust how polite or direct the tone of your content should be.
                <br />
                <br />
                <strong>Very Polite Example:</strong>
                <br /> "Thank you for your email. I will get back to you as soon
                as possible."
                <br />
                <br />
                <strong>Very Direct Example:</strong>
                <br /> "I need this by Friday. Send it to me now."
              </>
            }
            disabled={isDisabled}
          />

          <NotchedSlider
            name="emotionLevel"
            control={form.control}
            label="Emotion Level"
            labels={emotionLevels.map((level) => ({
              label: level.short,
              tooltip: level.long,
            }))}
            headerTooltip={
              <>
                Adjust how emotional or neutral the tone of your content should
                be.
                <br />
                <br />
                <strong>Very Emotional Example:</strong>
                <br /> "I'm so excited to share this news with you! 🎉"
                <br />
                <br />
                <strong>Very Neutral Example:</strong>
                <br /> "The project is complete. Please review the final
                report."
              </>
            }
            disabled={isDisabled}
          />

          <NotchedSlider
            name="assertivenessLevel"
            control={form.control}
            label="Assertiveness Level"
            labels={assertivenessLevels.map((level) => ({
              label: level.short,
              tooltip: level.long,
            }))}
            headerTooltip={
              <>
                Adjust how assertive or passive the tone of your content should
                be.
                <br />
                <br />
                <strong>Very Assertive Example:</strong>
                <br /> "I need this by Friday. Send it to me now."
                <br />
                <br />
                <strong>Very Passive Example:</strong>
                <br /> "I'll get back to you on this matter as soon as
                possible."
              </>
            }
            disabled={isDisabled}
          />
        </div>

        <div className="flex gap-x-4 mt-16" style={{ marginTop: 64 }}>
          <Button
            onClick={() => setShowTextVoiceAnalysisDialog(true)}
            type="button"
            className="flex-1"
            variant="outlineBlur"
            disabled={isDisabled}
          >
            Analyze Text
          </Button>
          <Button
            onClick={() => setShowPreviewDialog(true)}
            type="button"
            className="flex-1"
            variant="outlineBlur"
            disabled={isDisabled}
          >
            Open Preview Dialog
          </Button>
          <Button
            type="submit"
            isLoading={isLoading}
            disabled={
              !form.formState.isValid ||
              !isNameUnique(form.watch("name")) ||
              isDisabled
            }
            className="flex-1"
            variant="primaryBlur"
          >
            {initialData ? "Update Brand Voice" : "Create Brand Voice"}
          </Button>
        </div>
      </form>
      <BrandVoicePreviewDialog
        preview={preview}
        show={showPreviewDialog}
        onHide={() => {
          setShowPreviewDialog(false);
          setPreview(null);
        }}
        formalityLevel={form.watch("formalityLevel") + 1}
        politenessLevel={form.watch("politenessLevel") + 1}
        emotionLevel={form.watch("emotionLevel") + 1}
        assertivenessLevel={form.watch("assertivenessLevel") + 1}
        context={previewContext}
        setContext={setPreviewContext}
        onGeneratePreview={handleGeneratePreview}
        isGeneratingPreview={isGeneratingPreview}
      />
      <TextVoiceAnalysisDialog
        show={showTextVoiceAnalysisDialog}
        onHide={() => setShowTextVoiceAnalysisDialog(false)}
        onApplyAnalysis={handleApplyAnalysis}
      />
    </Form>
  );
};
