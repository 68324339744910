import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements";
import { Button } from "@/components/Elements/Button";
import { Checkbox } from "@/components/Elements/Checkbox";
import React, { useEffect, useState } from "react";
import { updateBrandVoice } from "../../api/updateBrandVoice";
import { BrandVoice } from "../../types";

interface SelectPrimaryBrandVoicesDialogProps {
  open: boolean;
  onClose: () => void;
  brandVoices: BrandVoice[];
  voiceLimit: number;
}

const SelectPrimaryBrandVoicesDialog: React.FC<
  SelectPrimaryBrandVoicesDialogProps
> = ({ open, onClose, brandVoices, voiceLimit }) => {
  const [selectedVoices, setSelectedVoices] = useState<BrandVoice[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    // Initialize with currently primary brand voices
    setSelectedVoices(brandVoices.filter((voice) => voice.primary));
  }, [brandVoices]);

  const handleToggleVoice = (voice: BrandVoice) => {
    if (selectedVoices.some((v) => v.id === voice.id)) {
      setSelectedVoices(selectedVoices.filter((v) => v.id !== voice.id));
    } else if (selectedVoices.length < voiceLimit) {
      setSelectedVoices([...selectedVoices, voice]);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    for (const voice of selectedVoices) {
      await updateBrandVoice({
        id: voice.id,
        updates: { primary: true },
      });
    }
    onClose();
    setIsSaving(false);
  };

  return (
    <Dialog open={open}>
      <DialogContent className="sm:max-w-[525px] p-0 bg-white dark:bg-zinc-900 border border-zinc-200 dark:border-zinc-700">
        <DialogHeader className="px-6 pt-6 pb-0">
          <DialogTitle className="text-xl font-semibold text-zinc-900 dark:text-zinc-100">
            Select Primary Brand Voice{voiceLimit > 1 ? "s" : ""}
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="px-6 pt-4">
          <p className="text-sm text-zinc-600 dark:text-zinc-400">
            Your plan supports {voiceLimit} active Brand Voice
            {voiceLimit > 1 ? "s" : ""}. Please select which{" "}
            {voiceLimit > 1 ? "ones" : "one"} to keep active. Inactive voice
            {voiceLimit > 1 ? "s" : ""} will be saved but won't be accessible
            without upgrading.
          </p>
        </DialogDescription>
        <div className="px-6 py-4 max-h-[400px] overflow-y-auto">
          {brandVoices.map((voice) => (
            <div key={voice.id} className="flex items-start space-x-2 py-2">
              <Checkbox
                id={`voice-${voice.id}`}
                checked={selectedVoices.some((v) => v.id === voice.id)}
                onCheckedChange={() => handleToggleVoice(voice)}
                disabled={
                  (selectedVoices.length >= voiceLimit &&
                    !selectedVoices.some((v) => v.id === voice.id)) ||
                  voice.primary
                }
              />
              <div className="flex flex-col">
                <label
                  htmlFor={`voice-${voice.id}`}
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {voice.name}
                </label>
              </div>
            </div>
          ))}
        </div>
        <DialogFooter className="px-6 pb-6 flex items-center justify-end space-x-2">
          <Button
            type="button"
            variant="primaryBlur"
            className="bg-blue-600 text-white hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600"
            onClick={handleSave}
            disabled={isSaving || selectedVoices.length !== voiceLimit}
            isLoading={isSaving}
          >
            Save Selection
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SelectPrimaryBrandVoicesDialog;
