import { Switch } from "@/components/Elements/Switch/Switch";
import React, { useCallback } from "react";
import { BooleanRuleDefinition, BooleanStyleGuideRule } from "../../../types";

interface BooleanRuleFieldProps {
  ruleDefinition: BooleanRuleDefinition;
  styleGuideRule: BooleanStyleGuideRule;
  onRuleChange: (updatedRule: BooleanStyleGuideRule) => void;
  disabled?: boolean;
}

export const BooleanRuleField: React.FC<BooleanRuleFieldProps> = ({
  ruleDefinition,
  styleGuideRule,
  onRuleChange,
  disabled,
}) => {
  const handleValueChange = useCallback(
    (checked: boolean) => {
      onRuleChange({ ...styleGuideRule, booleanValue: checked });
    },
    [styleGuideRule, onRuleChange]
  );

  return (
    <div className="flex items-center justify-between">
      <span className="text-sm text-zinc-700 dark:text-zinc-300">
        {ruleDefinition.description}
      </span>
      <Switch
        checked={disabled ? false : styleGuideRule.booleanValue}
        onCheckedChange={handleValueChange}
        size="sm"
        disabled={disabled}
      />
    </div>
  );
};
