import { Input } from "@/components/Elements/Input/Input";
import debounce from "lodash/debounce";
import React, { useCallback, useState } from "react";
import { NumericRuleDefinition, NumericStyleGuideRule } from "../../../types";

interface NumericRuleFieldProps {
  ruleDefinition: NumericRuleDefinition;
  styleGuideRule: NumericStyleGuideRule;
  onRuleChange: (updatedRule: NumericStyleGuideRule) => void;
  disabled?: boolean;
}

export const NumericRuleField: React.FC<NumericRuleFieldProps> = ({
  ruleDefinition,
  styleGuideRule,
  onRuleChange,
  disabled,
}) => {
  const [inputValue, setInputValue] = useState(
    styleGuideRule.numericValue.toString()
  );

  const debouncedHandleValueChange = useCallback(
    debounce((value: string) => {
      const numericValue = parseInt(value, 10);
      if (!isNaN(numericValue)) {
        onRuleChange({ ...styleGuideRule, numericValue });
      }
    }, 800),
    [styleGuideRule, onRuleChange]
  );

  const handleInputChange = (value: string) => {
    const numericValue = value.replace(/[^0-9]/g, "");
    const parsedValue = parseInt(numericValue, 10);

    let clampedValue = parsedValue;
    if (!isNaN(parsedValue)) {
      if (ruleDefinition.minValue !== undefined) {
        clampedValue = Math.max(ruleDefinition.minValue, clampedValue);
      }
      if (ruleDefinition.maxValue !== undefined) {
        clampedValue = Math.min(ruleDefinition.maxValue, clampedValue);
      }
    }

    setInputValue(clampedValue.toString());
    debouncedHandleValueChange(clampedValue.toString());
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !/[0-9]/.test(event.key) &&
      !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(
        event.key
      )
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <p className="text-xs text-zinc-500 dark:text-zinc-400 mb-2">
        {ruleDefinition.description}
      </p>
      <Input
        type="number"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        min={ruleDefinition.minValue}
        max={ruleDefinition.maxValue}
        className="w-full"
        disabled={disabled}
      />
    </>
  );
};
