import { Button } from "@/components/Elements";
import { FraseResearchBot } from "@/components/Elements/Logo/FraseResearchBot";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Elements/Table";
import { ContentLayout } from "@/components/Layout";
import { useSubscription } from "@/features/auth/api/getSubscription";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { clearPrimaryBrandVoices } from "../api/clearPrimaryBrandVoices";
import { useGetBrandVoicesForOrg } from "../api/getBrandVoicesForOrg";
import { BrandVoiceForm } from "../components/BrandVoiceForm";
import { BrandVoiceList } from "../components/BrandVoiceList";
import { ConfirmDeleteBrandVoiceDialog } from "../components/ConfirmDeleteBrandVoiceDialog";
import SelectPrimaryBrandVoicesDialog from "../components/SelectPrimaryBrandVoicesDialog";
import { UnsavedChangesDialog } from "../components/UnsavedChangesDialog";
import UpgradeToContinueDialog from "../components/UpgradeToContinueDialog";
import { BrandVoice as BrandVoiceType } from "../types";

export const BRAND_VOICE_LIMITS = {
  free: 1,
  solo: 1,
  basic: 1,
  team: 5,
  enterprise: Infinity,
} as const;

const getBrandVoiceLimit = (plan?: string, appsumo?: boolean): number => {
  if (appsumo) return BRAND_VOICE_LIMITS.team;
  if (!plan || plan.includes("solo")) return BRAND_VOICE_LIMITS.free;
  if (plan.includes("team")) return BRAND_VOICE_LIMITS.team;
  if (plan.includes("enterprise")) return BRAND_VOICE_LIMITS.enterprise;
  return BRAND_VOICE_LIMITS.basic;
};

export const BrandVoice = () => {
  const [hideLanding, setHideLanding] = useState(false);
  const { data: subscription, isLoading: isSubscriptionLoading } =
    useSubscription({});
  const navigate = useNavigate();
  const { data: brandVoices, isLoading } = useGetBrandVoicesForOrg();

  const [selectedBrandVoice, setSelectedBrandVoice] = useState<
    BrandVoiceType | "ADD_NEW"
  >("ADD_NEW");
  const [showDeleteDialog, setShowDeleteDialog] =
    useState<BrandVoiceType | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] =
    useState(false);
  const [pendingBrandVoice, setPendingBrandVoice] = useState<
    BrandVoiceType | "ADD_NEW" | null
  >(null);
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
  const [
    showSelectPrimaryBrandVoicesDialog,
    setShowSelectPrimaryBrandVoicesDialog,
  ] = useState(false);

  const brandVoiceLimit = getBrandVoiceLimit(
    subscription?.plan,
    subscription?.appsumo ?? false
  );
  const currentBrandVoiceCount = brandVoices?.length || 0;

  const sortedBrandVoices = useMemo(() => {
    if (currentBrandVoiceCount > brandVoiceLimit) {
      return brandVoices?.sort((a, _b) => (a.primary ? -1 : 1));
    }
    return brandVoices;
  }, [brandVoices, currentBrandVoiceCount, brandVoiceLimit]);

  useEffect(() => {
    if (
      brandVoices?.length === brandVoiceLimit &&
      selectedBrandVoice === "ADD_NEW"
    ) {
      setSelectedBrandVoice(brandVoices[0]);
    }
  }, [brandVoices, selectedBrandVoice]);

  useEffect(() => {
    const checkBrandVoices = async () => {
      if (!isLoading && brandVoices && brandVoiceLimit > 0) {
        const primaryDocs = brandVoices.filter((doc) => doc.primary);
        const isPrimaryCount = primaryDocs.length;

        if (currentBrandVoiceCount > brandVoiceLimit) {
          if (isPrimaryCount > brandVoiceLimit) {
            // Too many primary documents
            await clearPrimaryBrandVoices();
            setShowSelectPrimaryBrandVoicesDialog(true);
          } else if (isPrimaryCount < brandVoiceLimit) {
            // Not enough primary documents
            setShowSelectPrimaryBrandVoicesDialog(true);
          }
        }
      }
    };

    checkBrandVoices();
  }, [isLoading, brandVoices, brandVoiceLimit, currentBrandVoiceCount]);

  const handleSelectBrandVoice = useCallback(
    (brandVoice: BrandVoiceType | "ADD_NEW") => {
      if (hasUnsavedChanges) {
        setShowUnsavedChangesDialog(true);
        setPendingBrandVoice(brandVoice);
      } else {
        setSelectedBrandVoice(brandVoice);
      }
    },
    [hasUnsavedChanges]
  );

  const handleConfirmSwitch = useCallback(() => {
    if (pendingBrandVoice) {
      setSelectedBrandVoice(pendingBrandVoice);
      setHasUnsavedChanges(false);
    }
    setShowUnsavedChangesDialog(false);
    setPendingBrandVoice(null);
  }, [pendingBrandVoice]);

  const handleCancelSwitch = useCallback(() => {
    setShowUnsavedChangesDialog(false);
    setPendingBrandVoice(null);
  }, []);

  const handleFormSuccess = useCallback((newBrandVoice: BrandVoiceType) => {
    setSelectedBrandVoice(newBrandVoice);
    setHasUnsavedChanges(false);
  }, []);

  const handleDeleteSuccess = useCallback(() => {
    if (
      selectedBrandVoice !== "ADD_NEW" &&
      showDeleteDialog?.id === selectedBrandVoice.id
    ) {
      setSelectedBrandVoice("ADD_NEW");
    }
    setShowDeleteDialog(null);
  }, [selectedBrandVoice, showDeleteDialog]);

  const selectedBrandVoiceId = useMemo(
    () =>
      selectedBrandVoice === "ADD_NEW"
        ? "ADD_NEW"
        : selectedBrandVoice?.id || null,
    [selectedBrandVoice]
  );

  const handleFormChange = useCallback((hasChanges: boolean) => {
    setHasUnsavedChanges(hasChanges);
  }, []);

  if (isLoading || isSubscriptionLoading) {
    return (
      <ContentLayout title="Brand Voice">
        <div className="relative flex items-center w-full h-full justify-center">
          <LogoSpinner variant="md" loadingText="Loading Brand Voice..." />
        </div>
      </ContentLayout>
    );
  }

  if (brandVoiceLimit === 0 || (currentBrandVoiceCount === 0 && !hideLanding)) {
    return (
      <ContentLayout title="Brand Voice">
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="bg-white dark:bg-zinc-800 rounded-md p-10 shadow-glow max-w-2xl border space-y-6 dark:border-zinc-700">
            <FraseResearchBot />
            <div className="space-y-6">
              <div className="space-y-4">
                <h1 className="text-2xl font-medium dark:text-white">
                  Discover the Power of Frase Brand Voice
                </h1>
                <p className="text-[15px] font-normal text-zinc-600 dark:text-zinc-200">
                  Your brand's voice is more than just words—it's the
                  personality that connects you with your audience. Frase Brand
                  Voice helps you create content that feels authentic,
                  consistent, and uniquely yours.
                </p>
              </div>

              <div className="space-y-4">
                <h2 className="text-lg font-medium dark:text-white">
                  Why Customers Love Brand Voice:
                </h2>
                <ul className="space-y-2 text-[15px] text-zinc-600 dark:text-zinc-200 pl-6">
                  <li className="list-disc">
                    <strong>Build a Strong Identity:</strong> Ensure every piece
                    of content reflects your brand's personality.
                  </li>
                  <li className="list-disc">
                    <strong>Speak Their Language:</strong> Adjust your tone to
                    resonate with different audience segments.
                  </li>
                  <li className="list-disc">
                    <strong>Unite Your Team:</strong> Empower your team to write
                    in one cohesive voice.
                  </li>
                </ul>
              </div>
              <Table className="w-full mb-4">
                <TableHeader className="bg-zinc-50 dark:bg-zinc-800">
                  <TableRow>
                    <TableHead className="w-1/2 py-1 px-4 text-left text-xs font-semibold text-zinc-900 dark:text-white">
                      Plan
                    </TableHead>
                    <TableHead className="w-1/2 py-1 px-4 text-right text-xs font-semibold text-zinc-900 dark:text-white">
                      Brand Voices
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                      Free
                    </TableCell>
                    <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                      {BRAND_VOICE_LIMITS.free}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                      Basic
                    </TableCell>
                    <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                      {BRAND_VOICE_LIMITS.basic}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                      Team
                    </TableCell>
                    <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                      {BRAND_VOICE_LIMITS.team}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                      Enterprise
                    </TableCell>
                    <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                      Unlimited
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {brandVoiceLimit === 0 && (
                <Button
                  variant="primaryBlur"
                  size="sm"
                  className="bg-emerald-100 w-full"
                  onClick={() => {
                    navigate("/app/settings/plans");
                  }}
                >
                  Upgrade to access Brand Voice
                </Button>
              )}
              {brandVoiceLimit > 0 && (
                <Button
                  variant="primaryBlur"
                  size="sm"
                  className="bg-emerald-100 w-full"
                  onClick={() => {
                    setHideLanding(true);
                    setSelectedBrandVoice("ADD_NEW");
                  }}
                >
                  Create Brand Voice
                </Button>
              )}
            </div>
          </div>
        </div>
      </ContentLayout>
    );
  }

  return (
    <ContentLayout title="Brand Voice">
      <div className="flex h-full">
        <div className="w-[280px] overflow-y-auto border-r border-gray-200 dark:border-gray-700">
          <BrandVoiceList
            brandVoices={sortedBrandVoices || []}
            onSelectBrandVoice={handleSelectBrandVoice}
            onDeleteBrandVoice={setShowDeleteDialog}
            selectedBrandVoiceId={selectedBrandVoiceId}
            brandVoiceLimit={brandVoiceLimit}
            currentBrandVoiceCount={currentBrandVoiceCount}
            onLimitReached={() => setShowUpgradeDialog(true)}
          />
        </div>
        <div className="flex-1 p-4 overflow-y-auto">
          <BrandVoiceForm
            initialData={
              selectedBrandVoice === "ADD_NEW" ? undefined : selectedBrandVoice
            }
            onSuccess={handleFormSuccess}
            onFormChange={handleFormChange}
            overLimit={currentBrandVoiceCount > brandVoiceLimit}
          />
        </div>
        {showDeleteDialog && (
          <ConfirmDeleteBrandVoiceDialog
            show={Boolean(showDeleteDialog)}
            onHide={() => setShowDeleteDialog(null)}
            brandVoice={showDeleteDialog}
            onSuccess={handleDeleteSuccess}
          />
        )}
        <UnsavedChangesDialog
          show={showUnsavedChangesDialog}
          onCancel={handleCancelSwitch}
          onConfirm={handleConfirmSwitch}
        />
      </div>
      <UpgradeToContinueDialog
        open={showUpgradeDialog}
        onClose={() => setShowUpgradeDialog(false)}
        onCancel={() => setShowUpgradeDialog(false)}
        currentBrandVoiceCount={currentBrandVoiceCount}
        brandVoiceLimit={brandVoiceLimit}
      />
      <SelectPrimaryBrandVoicesDialog
        open={showSelectPrimaryBrandVoicesDialog}
        onClose={() => setShowSelectPrimaryBrandVoicesDialog(false)}
        brandVoices={brandVoices || []}
        voiceLimit={brandVoiceLimit}
      />
    </ContentLayout>
  );
};
