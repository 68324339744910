import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
} from "@/components/Elements";
import { FraseResearchBot } from "@/components/Elements/Logo/FraseResearchBot";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import { ContentLayout } from "@/components/Layout";
import { useSubscription } from "@/features/auth/api/getSubscription";
import { useNotificationStore } from "@/stores/notifications";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useCreateStyleGuide } from "../api/createStyleGuide";
import { useGetRuleDefinitions } from "../api/getRuleDefinitions";
import { useGetStyleGuidesForOrg } from "../api/getStyleGuidesForOrg";
import { ConfirmDeleteStyleGuideDialog } from "../components/ConfirmDeleteStyleGuideDialog";
import { StyleGuideForm } from "../components/StyleGuideForm";
import { StyleGuideList } from "../components/StyleGuideList";
import {
  BooleanRuleDefinition,
  ChoiceRuleDefinition,
  NumericRuleDefinition,
  PlanAccessLevel,
  SpecificRuleDefinition,
  StyleGuide as StyleGuideType,
} from "../types";

export const StyleGuide = () => {
  const { data: styleGuides, isLoading } = useGetStyleGuidesForOrg();
  const { data: ruleDefinitions } = useGetRuleDefinitions();
  const { data: subscription, isLoading: isLoadingSubscription } =
    useSubscription({});
  const [selectedStyleGuide, setSelectedStyleGuide] =
    useState<StyleGuideType | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] =
    useState<StyleGuideType | null>(null);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const createStyleGuideMutation = useCreateStyleGuide();
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  const hasNameCollision = useCallback(
    (name: string) => {
      return (
        styleGuides?.some(
          (styleGuide) => styleGuide.name.toLowerCase() === name.toLowerCase()
        ) || false
      );
    },
    [styleGuides]
  );

  const form = useForm<{ name: string; description: string }>({
    defaultValues: { name: "", description: "" },
  });

  const userPlanLevel: PlanAccessLevel = useMemo(() => {
    const planLevels: PlanAccessLevel[] = [
      "free",
      "basic",
      "team",
      "enterprise",
    ];

    if (subscription?.plan) {
      for (let i = planLevels.length - 1; i >= 0; i--) {
        if (subscription.plan.includes(planLevels[i])) {
          return planLevels[i];
        }
      }
    }

    if (subscription?.appsumo) {
      return "team";
    }

    return "free";
  }, [subscription, isLoadingSubscription]);

  const filteredRuleDefinitions = useMemo<SpecificRuleDefinition[]>(() => {
    return (ruleDefinitions || []).map((rule): SpecificRuleDefinition => {
      switch (rule.ruleType) {
        case "choice":
          return rule as ChoiceRuleDefinition;
        case "numeric":
          return rule as NumericRuleDefinition;
        case "boolean":
          return rule as BooleanRuleDefinition;
        default:
          throw new Error(`Unknown rule type: ${rule.ruleType}`);
      }
    });
  }, [ruleDefinitions]);

  const closeCreateDialog = () => {
    setShowCreateDialog(false);
    form.reset();
  };

  const handleCreateStyleGuide = useCallback(
    async (data: { name: string; description: string }) => {
      if (hasNameCollision(data.name)) {
        addNotification({
          title: "Error",
          message: `A style guide with the name "${data.name}" already exists.`,
          type: "error",
        });
        return;
      }
      try {
        const newStyleGuide = await createStyleGuideMutation.mutateAsync(data);
        await queryClient.invalidateQueries(["styleGuidesForOrg"]);
        addNotification({
          title: "Style Guide Created",
          message: "Your new style guide has been created successfully.",
          type: "success",
        });
        closeCreateDialog();
        setSelectedStyleGuide(newStyleGuide);
      } catch (error) {
        addNotification({
          title: "Error",
          message: "Failed to create style guide. Please try again.",
          type: "error",
        });
      }
    },
    [
      createStyleGuideMutation,
      queryClient,
      addNotification,
      closeCreateDialog,
      setSelectedStyleGuide,
    ]
  );

  if (isLoading) {
    return (
      <ContentLayout title="Style Guide">
        <div className="relative flex items-center w-full h-full justify-center">
          <LogoSpinner variant="md" loadingText="Loading Style Guides..." />
        </div>
      </ContentLayout>
    );
  }

  return (
    <div className="flex h-full">
      <div className="w-[280px] overflow-y-auto border-r border-gray-200 dark:border-gray-700">
        <StyleGuideList
          styleGuides={styleGuides || []}
          onSelectStyleGuide={setSelectedStyleGuide}
          onDeleteStyleGuide={(styleGuideToDelete) =>
            setShowDeleteDialog(styleGuideToDelete)
          }
          selectedStyleGuideId={selectedStyleGuide?.id || null}
          onCreateNewStyleGuide={() => setShowCreateDialog(true)}
        />
      </div>
      <div className="flex-1 p-4 overflow-y-auto">
        {selectedStyleGuide ? (
          <StyleGuideForm
            initialData={selectedStyleGuide}
            ruleDefinitions={filteredRuleDefinitions}
            onSuccess={setSelectedStyleGuide}
            userPlanLevel={userPlanLevel}
          />
        ) : (
          <div className="flex flex-col items-center justify-center h-full">
            <div className="bg-white dark:bg-zinc-800 rounded-md p-10 shadow-glow max-w-2xl border space-y-6 dark:border-zinc-700">
              <FraseResearchBot />
              <div className="space-y-6">
                <div className="space-y-4">
                  <h1 className="text-2xl font-medium dark:text-white">
                    Bring Order to Your Content with the Style Guide
                  </h1>
                  <p className="text-[15px] font-normal text-zinc-600 dark:text-zinc-200">
                    The Style Guide gives you the power to define exactly how
                    your content should look, sound, and feel. Set detailed
                    rules for tone, structure, and formatting to ensure every
                    piece aligns with your standards.
                  </p>
                </div>

                <div className="space-y-4">
                  <h2 className="text-lg font-medium dark:text-white">
                    Key Features:
                  </h2>
                  <ul className="space-y-2 text-[15px] text-zinc-600 dark:text-zinc-200 pl-6">
                    <li className="list-disc">
                      <strong>Custom Rules:</strong> Control punctuation, tone,
                      sentence clarity, and more.
                    </li>
                    <li className="list-disc">
                      <strong>Real-Time Feedback:</strong> Highlight and correct
                      inconsistencies as you write.
                    </li>
                    <li className="list-disc">
                      <strong>AI Alignment:</strong> Tailor AI-generated content
                      to match your specific guidelines.
                    </li>
                  </ul>
                  <div style={{ marginTop: "26px" }}>
                    <p className="text-[15px] font-normal text-zinc-600 dark:text-zinc-200">
                      Whether you're managing a brand voice or fine-tuning
                      editorial standards, the Style Guide ensures your content
                      stays consistent and polished—every time.
                    </p>
                  </div>
                </div>
                <Button
                  variant="primaryBlur"
                  size="sm"
                  className="bg-emerald-100 w-full"
                  onClick={() => setShowCreateDialog(true)}
                >
                  <div className="flex items-center justify-center">
                    <PlusIcon className="h-5 w-5 mr-2" />
                    <span>Set up your Style Guide</span>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      {showDeleteDialog && (
        <ConfirmDeleteStyleGuideDialog
          show={Boolean(showDeleteDialog)}
          onHide={() => setShowDeleteDialog(null)}
          styleGuide={showDeleteDialog}
          onSuccess={() => {
            if (
              selectedStyleGuide &&
              showDeleteDialog.id === selectedStyleGuide.id
            ) {
              setSelectedStyleGuide(null);
            }
            setShowDeleteDialog(null);
          }}
        />
      )}
      <Dialog open={showCreateDialog} onOpenChange={closeCreateDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader className="pb-0 flex items-center justify-between">
            <DialogTitle>Create New Style Guide</DialogTitle>
            <DialogCloseButton close={closeCreateDialog} />
          </DialogHeader>
          <DialogDescription className="px-4 pb-4">
            Create a new style guide to define and enforce your content's
            structure and style. This will allow us to provide real-time
            suggestions for manually written text.
          </DialogDescription>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleCreateStyleGuide)}
              className="space-y-4 px-4"
            >
              <FormField
                control={form.control}
                name="name"
                rules={{ required: "Name is required" }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Style Guide Name</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="Enter a name for your style guide"
                        maxLength={40}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="Describe your style guide"
                        maxLength={120}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </form>
          </Form>
          <DialogFooter className="px-4 py-4 flex items-center justify-between sm:justify-between bg-white dark:bg-zinc-800 rounded-bl-md rounded-br-md border-t dark:border-t-zinc-700">
            <Button
              type="button"
              variant="outlineBlur"
              onClick={closeCreateDialog}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primaryBlur"
              isLoading={createStyleGuideMutation.isLoading}
              onClick={form.handleSubmit(handleCreateStyleGuide)}
              disabled={!form.formState.isValid}
            >
              Create Style Guide
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
