import { Button } from "@/components/Elements/Button";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import { useKnowledgeHubDocuments } from "@/features/knowledge-hub/hook";
import React from "react";

interface KnowledgeHubDocListProps {
  onSelectDocument: (documentHash: string) => void;
  isAnalyzing: boolean;
}

export const KnowledgeHubDocList: React.FC<KnowledgeHubDocListProps> = ({
  onSelectDocument,
  isAnalyzing,
}) => {
  const { useableDocuments, isLoading } = useKnowledgeHubDocuments();

  if (isLoading || isAnalyzing) {
    return (
      <div className="flex items-center justify-center h-full">
        <LogoSpinner
          variant="sm"
          loadingText={
            isAnalyzing ? "Analyzing document..." : "Loading documents..."
          }
        />
      </div>
    );
  }

  if (!useableDocuments || useableDocuments.length === 0) {
    return <div>No documents found in the Knowledge Hub.</div>;
  }

  return (
    <div className="space-y-2">
      {useableDocuments.map((doc) => (
        <Button
          key={doc.id}
          onClick={() => onSelectDocument(doc.hash)}
          variant="outline"
          className="w-full justify-start"
          disabled={isAnalyzing}
        >
          {doc.name}
        </Button>
      ))}
    </div>
  );
};
